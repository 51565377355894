<template>
    <div ref="videoWrapper" class="video-thumbnail">
        <iframe
            ref="videoFrame"
            v-if="isVisible"
            :src="computedSrc"
            frameborder="0"
            allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';

// Props
const props = defineProps({
    src: {
        type: String,
        required: true
    }
});

// State
const videoId = ref('');
const videoWrapper = ref(null);
const videoFrame = ref(null);
const isVisible = ref(true);
let observer = null;

// Extract Video ID
const extractVideoId = (url) => {
    const regExp =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/[^\n\s\/]+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);

    if (match && match[1]) {
        videoId.value = match[1];
    } else {
        console.error('Invalid YouTube URL');
    }
};

// Computed Property for `src`
const computedSrc = computed(() => {
    return `https://www.youtube.com/embed/${videoId.value}?enablejsapi=1&autoplay=1&mute=1`;
});

// Intersection Observer
const handleIntersection = (entries) => {
    const videoIframe = videoFrame.value?.contentWindow;
    entries.forEach((entry) => {
        isVisible.value = entry.isIntersecting
        if (entry.isIntersecting) {
            videoIframe.postMessage('{"method":"play"}', '*');
        } else if (videoIframe.value?.contentWindow) {
            videoIframe.postMessage('{"method":"pause"}', '*');
        }
    });
};

// Lifecycle Hooks
onMounted(() => {
    extractVideoId(props.src);

    observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
    if (videoWrapper.value) {
        observer.observe(videoWrapper.value);
    }
});

onUnmounted(() => {
    if (observer && videoWrapper.value) {
        observer.unobserve(videoWrapper.value);
    }
});
</script>

<style scoped>
.video-thumbnail {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-thumbnail iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.video-thumbnail .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: white;
    pointer-events: none;
    z-index: 2;
}
</style>
